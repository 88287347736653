/* eslint-disable @typescript-eslint/ban-ts-comment */
window.Realworld = {
  invoke: (name: string, ...params: unknown[]) => {
    // @ts-ignore
    if (window[`mobile_${name}`]) {
      // @ts-ignore
      window[`mobile_${name}`].postMessage(
        params.length ? params.join('|') : ''
      );
    } else if (window.webkit?.messageHandlers[name]) {
      window.webkit.messageHandlers[name].postMessage(
        // @ts-ignore TS2556: A spread argument must either have a tuple type or be passed to a rest parameter.
        ...(params.length ? params : [null])
      );
    } else if (window.RealworldBridge && name in window.RealworldBridge) {
      // @ts-ignore TS2556: A spread argument must either have a tuple type or be passed to a rest parameter.
      window.RealworldBridge[name](...(params.length ? params : [null]));
    }
  },
  isWebView: () =>
    Boolean(
      window.isFlutter ||
        window.webkit?.messageHandlers ||
        window.RealworldBridge
    ),
  close: function () {
    window.Realworld.invoke('closeWebApp');
  },
  refreshToken: function () {
    window.Realworld.invoke('refreshToken');
  },
  changeEnableScroll: function (nextValue: boolean) {
    if (nextValue) {
      window.Realworld.invoke('enableScroll');
    } else {
      window.Realworld.invoke('disableScroll');
    }
  },
  getUserId: () => localStorage.getItem('Realworld.userId'),
};
