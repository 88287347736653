exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-creators-creator-id-games-tsx": () => import("./../../../src/pages/creators/[creatorId]/games.tsx" /* webpackChunkName: "component---src-pages-creators-creator-id-games-tsx" */),
  "component---src-pages-creators-creator-id-index-tsx": () => import("./../../../src/pages/creators/[creatorId]/index.tsx" /* webpackChunkName: "component---src-pages-creators-creator-id-index-tsx" */),
  "component---src-pages-feed-detail-add-tsx": () => import("./../../../src/pages/feedDetailAdd.tsx" /* webpackChunkName: "component---src-pages-feed-detail-add-tsx" */),
  "component---src-pages-feed-detail-edit-tsx": () => import("./../../../src/pages/feedDetailEdit.tsx" /* webpackChunkName: "component---src-pages-feed-detail-edit-tsx" */),
  "component---src-pages-feed-detail-view-tsx": () => import("./../../../src/pages/feedDetailView.tsx" /* webpackChunkName: "component---src-pages-feed-detail-view-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-view-all-tsx": () => import("./../../../src/pages/viewAll.tsx" /* webpackChunkName: "component---src-pages-view-all-tsx" */)
}

